<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToPage('/bigscreen/menuPage')">巴林右旗{{ mapName }}防疫统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>防疫牲畜数量</span></div>
          <bing-tu :value="data1" />
        </div>

        <div class="left_box">
          <div class="title"><span>疫苗使用数量</span></div>
          <ban-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <da-ban-map />
          <!-- <da-ban-map v-if="mapName == '大板镇'" />
          <su-bo-ri-ga v-if="mapName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="mapName == '幸福之路苏木'" />
          <ba-yan-hu-shuo-hamlet v-if="mapName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun-hamlet v-if="mapName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="mapName == '大板煤电化基地'" />

          <ba-yan-ta-la v-if="mapName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="mapName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="mapName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="mapName == '西拉沐沦苏木'" /> -->
        </div>

        <!-- <div class="context_box">
          全镇总土地面积 1925 平方公里，
          全镇大小畜存栏 22.6 万头只，其中大畜牛 3.58 万头。
          永久性棚圈建设 5.3 万平方米，青贮窖建设 8300 立方米。
          至 2022 年人口 77003 人。总人口中男性 38520 人。
        </div> -->

        <div class="bottom_box">
          <div class="title"><span>发病统计</span></div>
          <kong-xin-bing-tu class="chart" :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="mu_hu_list">
          <div class="title"><span>嘎查村防疫统计</span></div>

          <div class="list_box">
            <div class="list_item" v-for="(item, index) in hamletList" :key="index">
              <span v-if="index < 9">0{{ index + 1 }}</span> 
              <span v-else>{{ index+1 }}</span> 

              <div class="name" @click="goToPage(`/bigscreen/hamletFangYiTongJi${item.name}`)">{{ item.name }}</div> 

              <div class="value">防疫数量： <span class="font_number main_color">{{ item.value }}</span></div> 
            </div>
          </div>

        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import BanBingTu from '../charts/ban-bing-tu.vue';


  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'

  export default {
    name: 'VillageFangYiTongJi',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      BanBingTu,
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
    },
    data() {
      return {
        activeIndex: 0,
        mapName: '巴林右旗大板镇',

        data1: {},
        data2: {},
        data3: {},

        hamletList: [
          { name: '红旗嘎查', value: 3289 },
          { name: '吉布吐嘎查', value: 2578 },
          { name: '西哈嘎查', value: 1956 },
          { name: '十家子嘎查', value: 1664 },
          { name: '查干诺尔嘎查', value: 1239 },
          { name: '浩饶沁嘎查', value: 1217 },
          { name: '大冷嘎查', value: 1189 },
          { name: '古力古台嘎查', value: 1078 },
          { name: '苏艾力嘎查', value: 819 },
          { name: '太布呆嘎查', value: 784 },
          { name: '昭胡都格嘎查', value: 526 },
          { name: '新达冷村', value: 517 },
          { name: '苏艾里嘎查', value: 489 },
          { name: '西拉汰沦厚查', value: 475 },
          { name: '巴彦汉嘎查', value: 379 },
          { name: '温根吐嘎查', value: 334 },
          { name: '翁根图毛都嘎查', value: 321 },
          { name: '红星村', value: 267 },
          { name: '莫日古其格', value: 229 },
          { name: '独希嘎查', value: 219 },
          { name: '新立村', value: 207 },
          { name: '友爱村', value: 198 },
          { name: '益斯毛都嘎查', value: 169 },
          { name: '准宝日嘎查', value: 157 },
          { name: '巴罕村', value: 142 },
          { name: '套白村', value: 115 }
        ],
      }
    },
    mounted() {
      this.mapName = this.$route.params.val
      
      setTimeout(() => {
        this.initChart()
      }, 100)

    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          unit: '头',
          data: [
            {"name": "牛", "value": 18548, "unit": ''},
            {"name": "马", "value": 9832, "unit": ''},
            {"name": "绵羊", "value": 7821, "unit": ''},
            {"name": "山羊", "value": 9676, "unit": ''},
            {"name": "驴", "value": 243, "unit": ''},
            {"name": "骆驼", "value": 3451, "unit": ''}
          ]
        }

        this.data2 = {
          unit: '支',
          data: [
            {"name": "疫苗A", "value": 23570, "unit": ''},
            {"name": "疫苗B", "value": 12432, "unit": ''},
            {"name": "疫苗C", "value": 12521, "unit": ''},
            {"name": "疫苗D", "value": 13676, "unit": ''},
            {"name": "疫苗E", "value": 2343, "unit": ''},
            {"name": "疫苗F", "value": 3451, "unit": ''}
          ]
        }

        this.data3 = {
          unit: '起',
          data: [
            {"name": "疯牛病", "value": 23570, "unit": ''},
            {"name": "牛结核", "value": 12432, "unit": ''},
            {"name": "布病", "value": 12521, "unit": ''},
            {"name": "擒流感", "value": 13676, "unit": ''}
          ]
        }
      },

      // 跳转页面
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `巴林右旗${this.mapName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `${this.mapName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .bottom_box { 
    margin-top: 17%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .title { 
      span { font-size: 12px !important; }
    }
  }
</style>